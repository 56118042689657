
#right-sidebar {
  width: 300px;
  flex-shrink: 0;
  box-sizing: border-box;
  border-left: 1px solid rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  background: #171717;

  /* Add the following code */
  margin-left: auto;
  /* End of added code */
  .cell {
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    margin: 16px 0;
    text-align: left;
    h3 {
      margin: 0;
      padding: 0;
      margin-bottom: 8px;
      color: #fff;
    }
    p {
      margin-bottom: 6px;
    }
  }
  .snippet-library {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
  }
  .snippet-pill {
    font-size: 12px;
    padding: 4px;
    font-family: monospace;
    border: 1px dashed;
  }
}
// I want to make it so that the right sidebar has a border on the left side
// I want to make it so that the right sidebar has a border on the bottom of each cell
// I want to make it so that the right sidebar has a margin of 16px on the top and bottom of each cell
// I want to make it so that the right sidebar has a padding of 16px on the left and right of each cell

// #right-sidebar {
//   width: 300px;
//   flex-shrink: 0;

//   box-sizing: border-box;

//   border-left: 1px solid rgba(0, 0, 0, 0.24);
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;

//   align-items: flex-end;
//   .cell {
//     padding: 16px;
//     border-bottom: 1px solid rgba(0, 0, 0, 0.24);
//     margin: 16px 0;

//     text-align: left;
//     h3 {
//       margin: 0;
//       padding: 0;
//       margin-bottom: 8px;
//     }
//     p {
//       margin-bottom: 6px;
//     }
//   }

//   .snippet-library {
//     display: flex;
//     flex-direction: column;
//     gap: 8px;

//     margin-bottom: 8px;
//   }

