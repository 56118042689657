#model-type-warning {
  background: darkslategray;
  font-size: 12px;
  padding: 12px;
  border-radius: 4px;
  font-weight: 600;
}

#clone-sidebar {
  width: 260px;
  box-sizing: border-box;
  background: #171717;
  height: 100vh;
  position: relative;
  flex-shrink: 0;

  .cell {
    position: relative;
  }

  .session-list-container {
    height: 300px;
    overflow: overlay;
  }

  .stickied-footer {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 8px !important;
    background: #171717;
    border-top: 1px solid rgba(255, 255, 255, 0.24);

    justify-content: flex-end;
    display: flex;

    .tooltip-button {
      margin: 0;
    }
  }

  h3 {
    text-align: left;
    font-size: 12px;
    margin: 4px 0px 12px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.4);
  }

  #session-meta {
    display: flex;
    flex-direction: column;
    gap: 12px;

    button {
      margin: 0;
    }

    .delete-chat-button {
      color: #ff3333;
    }

    button.special-white {
      background: #fff;
      color: #000;
      padding: 12px 16px;
      border: none;
      border-radius: 16px;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  .cell {
    padding: 16px;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);

    &:last-child {
      border-bottom: none;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    list-style: none;
    margin: -2px 0;
    position: relative;

    .delete-button {
      position: absolute;
      background-color: transparent;
      border: none;

      position: absolute;
      background-color: transparent;
      border: none;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;

      display: none;
    }

    &:hover {
      .delete-button {
        display: block;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;

        &:hover {
          background-color: red;
        }
      }
    }
  }

  .tooltip {
    display: inline-block;
  }

  li.active button.main {
    background: rgba(255, 255, 255, 0.16);
  }

  li button.main {
    background: transparent;
    font-size: 14px;
    color: #fff;

    line-height: 1.4;
    min-height: 36px;

    cursor: pointer;
    transition: background 0.1s;
    border: none;
    width: 100%;

    padding: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    text-align: left;

    margin: 0 auto;
    display: flex;
    align-items: center;

    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }

    &.active {
      background: rgba(255, 255, 255, 0.16) !important;
    }
  }

  .workspaces-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 12px;
  }

  .workspace-button {
    width: 100%;
    height: 72px;
    border: 1px solid rgba(255, 255, 255, 0.08);

    .emoji {
      font-size: 24px;
      margin-bottom: 3px;
    }

    .label {
      color: rgba(255, 255, 255, 0.32);
    }

    &:hover {
      .label {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
