.tooltip-button {
  margin: 0 -3px;

  background: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  width: 32px;
  height: 32px;
  border-radius: 4px;

  &.auto-width {
    width: auto;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
  }
}

.tooltip {
  &::part(base__popup) {
    background-color: #000;
    font-size: 12px;
    padding: 4px 6px;
    color: rgba(255, 255, 255, 0.64);
    border-radius: 8px;
  }
}
