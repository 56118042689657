#form-tho {
  position: absolute;
  max-width: 700px;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

#chat-input {
  max-width: 800px;
  margin: 0 auto;
  width: calc(100% - 16px * 2);

  box-sizing: border-box;
  padding: 14px 16px;

  background: rgb(33, 33, 33);
  border: 1px solid rgb(255, 255, 255, 0.16);
  border-radius: 16px;

  position: absolute;
  bottom: 48px;
  left: 16px;

  color: #fff;
  font-size: 16px;
  font-family: var(--font);

  max-height: 60vh;
  overflow-y: overlay;

  &:focus {
    border: 1px solid rgb(255, 255, 255, 0.32);
    outline: none;
  }
}

.prompt-supplements {
  position: absolute;
  bottom: 0;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;

  .prompt-supplement {
    background: #000;
    padding: 6px 8px;
    font-size: 12px;
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    border: 1px solid rgba(255, 255, 255, 0.24);
    color: rgba(255, 255, 255, 0.67);
  }
}
