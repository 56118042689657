#workspace-container {
  padding: 80px 16px 16px;
  position: relative;
  width: 100%;
  height: 100%;

  .content {
    text-align: left;

    h3 {
      text-align: left;
      font-size: 12px;
      margin: 4px 0px 12px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.4);
    }

    section {
      margin-bottom: 32px;
    }

    .content-grid {
      display: flex;
      flex-direction: row;
      gap: 12px;
    }

    .asset-card {
      height: 200px;
      border: 1px solid rgba(255, 255, 255, 0.08);
      border-radius: 8px;
      padding: 12px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.04);
      font-size: 16px;
      color: #fff;
      transition: 100ms ease-in-out;
      align-items: start;
      display: flex;
      flex-direction: column;
      text-align: left;
      font-size: 14px;
      line-height: 1.5;
      width: 220px;

      &:hover {
        transform: scale(1.08) rotate(2deg);
      }

      &:active {
        transform: scale(1.03) rotate(0deg);
      }
    }
  }

  nav {
    background-color: #000;
    border: 1px solid rgba(255, 255, 255, 0.24);
    width: calc(100% - 32px);
    border-radius: 18px;
    height: 48px;
    position: absolute;
    top: 16px;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 16px;
    color: rgba(255, 255, 255, 0.64);

    padding: 16px;

    color: #fff;
  }
}
