.toast-container {
  --toastify-toast-min-height: 48px;

  .Toastify__toast {
    background: var(--toastify-color-dark);
    color: var(--toastify-text-color-dark);
    border: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 12px;
  }
}
