#chat-container {
  position: relative;
  width: 100%;

  .chat-log {
    position: relative;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    height: 100%;
    padding: 48px 64px 148px;
    margin: 0 auto;
    overflow-y: overlay;
  }
}
