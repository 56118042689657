.chat-entry {
  // white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;

  line-height: 1.5;

  padding: 16px;
  border: 1px dashed rgba(255, 255, 255, 0);
  border-radius: 8px;
  color: #fff;

  position: relative;

  &:hover {
    border: 1px dashed rgba(255, 255, 255, 0.32);
  }

  .model-maker {
    font-size: 10px;
    position: absolute;
    right: 8px;
    bottom: 8px;
    opacity: 0.4;
  }

  // &:not(:last-child) {
  //   max-height: 200px;
  //   overflow-y: scroll;
  //   border-bottom: 1px dashed rgba(255, 255, 255, 0.24);
  // }

  &.user .icon {
    background: gray;
  }

  &.model .icon {
    background: teal;
  }

  .chat-header {
    font-weight: 600;
    text-transform: capitalize;
    position: relative;

    .actions {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(-4px, -4px);
    }

    .icon {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      transform: translateX(-100%) translateY(2px) translateX(-12px);

      border-radius: 100%;
    }
  }

  .chat-body {
    code {
      background: #000;
      font-size: 14px;
      padding: 4px;
      border-radius: 8px;
    }

    pre {
      background: #000;
      font-size: 14px;
      padding: 16px;
      border-radius: 8px;
      overflow-x: scroll;
    }

    ol,
    ul {
      // line-height: 1;
    }

    p {
      line-height: 1.5;
      white-space: pre-wrap;
    }

    p,
    ol,
    ul {
      //margin: 0;
      // margin-bottom: 16px;
    }
  }
}
