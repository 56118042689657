@import url("./core/Global.scss");

:root {
  --font: "Sono Sans", Arial, sans-serif;
}

a {
  color: #61dafb;
}

html {
  scroll-behavior: smooth;
  background: rgb(var(--background) / 1);
  font-family: var(--font);
  background: rgba(33, 33, 33);
}

@font-face {
  font-family: "Sono";
  src: url("./assets/SonoSans-Regular.woff2") format("woff2");
  font-style: normal;
}

#app-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.dialog {
  &::part(base) {
    z-index: 10;
  }

  &::part(overlay) {
    background-color: rgba(0, 0, 0, 0.87);
  }

  &::part(panel) {
    line-height: 1.4;
    background-color: #171717;
    padding: 16px;
    z-index: 10;
    text-align: left;
    font-size: 14px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 8px;

    code {
      margin-top: 16px;
      text-align: left;
      font-size: 14px;
    }
  }
}

.dialog::part(header) {
  margin-bottom: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.24);
}

button {
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fafaf7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
